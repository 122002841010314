import React, { Component } from "react"
import { Link } from "react-scroll"

// Styles
import Styles from "./TopBarFilters.module.styl"

class TopBarFilters extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        entries: [],
        activeEntry: -1,
        isHovering: false,
    }

    onMouseEnterItem(index) {
        this.setState({
            activeEntry: index,
            isHovering: true,
        })
    }

    onMouseLeaveItem() {
        this.setState({
            activeEntry: -1,
            isHovering: false,
        })
    }

    generateNav() {
        this.navItemsRefs = []

        const Entries = this.state.entries.map((entry, index) => {
            return (
                <Link
                    key={`topbar-entry-${entry.slug}-${index}`}
                    className={`btn--secondary ${
                        Styles.TopBarFilters__nav__item
                    } ${
                        this.state.activeEntry !== index &&
                        this.state.isHovering
                            ? Styles.TopBarFilters__nav__item__idle
                            : ""
                    }`}
                    activeClass={Styles.TopBarFilters__nav__item__active}
                    to={entry.href}
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                    onMouseEnter={(e) => {
                        this.onMouseEnterItem(index)
                    }}
                    onMouseLeave={(e) => {
                        this.onMouseLeaveItem()
                    }}
                >
                    {entry.name}
                </Link>
            )
        })

        return Entries
    }

    render() {
        const Entries = this.generateNav()

        return (
            <div className={`${Styles.TopBarFilters}`}>
                <div className={Styles.TopBarFilters__inner}>
                    <div className={Styles.TopBarFilters__left}>
                        <span
                            className={`heading-5 ${Styles.TopBarFilters__label}`}
                        >
                            {this.props.label} :
                        </span>
                    </div>
                    <div className={Styles.TopBarFilters__right}>
                        <nav className={Styles.TopBarFilters__nav}>
                            {Entries}
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default TopBarFilters
